<template>
    <div>
        <div>
            <div class="exa-head">
                <div class="title"><i class="el-icon-edit-outline" style="color: #1ea0fa;"></i> 新建绩效考核</div>
                <div class="back"><el-button style="color: #1ea0fa;" @click="$router.go(-1)">返回</el-button></div>
            </div>
            <div class="content">
                <div class="row">
                    <div class="tips"><i class="el-icon-warning" style="color: #ff9c00;"></i> 请选择一个模板</div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <!-- <div class="w-block" style="padding: 2px;">
                            <el-input v-model="grid.sea.Func_Room_Name" placeholder="请输入关键字"
                                style="width: 300px;margin-left: 5px">
                                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
                            </el-input>
                        </div> -->
                        <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total"
                            :page-index="grid.sea.PAGE_INDEX + 1" @page-changed="loadPage">
                            <el-table-column type="index" label="序号" width="200" align="center" />
                            <el-table-column prop="Template_Name" label="模板名称" width="200" align="center" />
                            <el-table-column prop="Publish_User" label="题目数" />
                            <el-table-column label="创建时间">
                                <template slot-scope="scope">
                                    {{ scope.row.CREATED_DT | datetime('YYYY-MM-DD') }}
                                </template>
                            </el-table-column>

                            <el-table-column prop="Publish_User_Name" label="创建人" />

                            <el-table-column label="操作" width="250">
                                <template slot-scope="scope">
                                    <el-button type="text" icon="el-icon-edit" @click="show(scope.row)">预览</el-button>
                                    <el-button type="text" icon="el-icon-edit"
                                        @click="changeUser(scope.row)">选择使用</el-button>
                                </template>
                            </el-table-column>
                        </wgrid>
                    </div>
                </div>
            </div>
            <Edit ref="edit"></Edit>
        </div>
    </div>
</template>

<script>
import Wgrid from "@/components/wgrid";
import Edit from '../mould/edit.vue'
export default {
    components: { Wgrid, Edit },
    data() {
        return {
            grid: {
                sea: {
                    PAGE_SIZE: 12,
                    PAGE_INDEX: 0,
                    Func_Room_Name: '',
                },
                ls: [],
                total: 0,
                loading: false
            },
        }
    },
    mounted() {
        this.get()
    },
    methods: {
        get() {
            let self = this
            self.grid.loading = true
            this.whale.remote.getCollection({
                url: "/api/School/PFM/PfmTempalteSetting/GetPageList",
                data: { Key: '' },
                completed: function (its) {
                    self.grid.loading = false;
                    self.grid.ls = its
                    self.grid.total = its.length
                }
            })
        },
        changeUser(r) {
            console.log(r)
            // this.$router.push({ path: '/perform/exaCre2', query: { id: r.ID } })
            this.$router.push({ path: '/perform/exaEdit', query: { id: r.ID, tit: this.$route.query.tit } })
        },
        show(r) {
            this.$refs.edit.init(r)
        },
        loadPage(pi) {
            this.grid.sea.PAGE_INDEX = pi - 1;
            // this.get();
        },
    }
}
</script>
<style>
.exa-head {
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content {
    /* margin-top: 40px; */
}

.tips {
    /* width: 0%; */
    margin: 0 auto;
    text-align: center;
    background: #fff6e7;
    color: #333333;
    font-weight: bold;
    padding: 5px 20px;
}
</style>